import React from 'react'
import { SiteHeader as Component } from '@swp/components'

const items = {
  "": {
    "items": []
  }
}

const languages = []

export default function SiteHeader({ set = '', currentLanguage }) {
  return (
    <Component className="pos--fix --spacing4 --transparent" type={"SiteHeader"} item={{"color":"rgba(226,228,235,1)","colorHover":"rgba(255,255,255,1)","className":"fs--18 w--500"}} logo={{"src":"https://cdn.swbpg.com/o/37892/3c008313b2354803885cf5d2c9fa989f.png"}} fixed={true} title={{"color":"rgba(226,228,235,1)","content":"","className":"fs--26 w--600"}} button={{"content":"Kontaktovat","page":"/","section":"paticka","className":"btn-box btn-box--hvr2 btn-box--shape5 fs--18"}} layout={"3"} social={{"facebook":"ff","instagram":"rf"}} transparent={true} layoutMobile={"2"} backgroundColor={"var(--color-custom-2--95)"} items={items?.[set]?.items} languages={languages} currentLanguage={currentLanguage} />
  )
}