/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Odtah vozidel a likvidace vozidel"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1vtez5l --full pb--60 pt--60" name={"iy9vj7qal7e"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/e6887dc9021a423b803866d59b144379_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/e6887dc9021a423b803866d59b144379_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/e6887dc9021a423b803866d59b144379_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/e6887dc9021a423b803866d59b144379_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/e6887dc9021a423b803866d59b144379_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/e6887dc9021a423b803866d59b144379_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/e6887dc9021a423b803866d59b144379_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/e6887dc9021a423b803866d59b144379_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"<span style=\"color: var(--color-blend--85);\">NATAHNEMTO.CZ</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--50" name={"o-mne"} style={{"backgroundColor":"var(--color-dominant)"}}>
          
          <ColumnWrap className="column__flex --left el--2 pb--10 pt--10 flex--center" anim={""} animS={"5"} style={{"maxWidth":1310}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"} style={{"maxWidth":497}}>
              
              <Title className="title-box title-box--left fs--72" style={{"maxWidth":""}} content={"<br>Odtah vozidel<br>Likvidace<br>Výkup&nbsp;<br><br>"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37892/ae516f4d7ffb4cfb89aaa8aee92075f5_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37892/ae516f4d7ffb4cfb89aaa8aee92075f5_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37892/ae516f4d7ffb4cfb89aaa8aee92075f5_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37892/ae516f4d7ffb4cfb89aaa8aee92075f5_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/37892/ae516f4d7ffb4cfb89aaa8aee92075f5_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/37892/ae516f4d7ffb4cfb89aaa8aee92075f5_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--50 pt--50" name={"sluzby"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 pb--10 pt--10" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Výkup</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"- Zajistíme pro Vás výkup vozidel určených k likvidaci <br>&nbsp;- Odměna za nepojízdné vozidlo od 2.000 kč při převzetí na místě <br>&nbsp;- Zajistíme výkup pojízdných i nepojízdných vozidel ( s mechanickými i karosářskými vadami ) <br>&nbsp;- Individuální přístup při výkupu a necenění vozidel pro firmy ale i fyzické osoby&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"4"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Odtah a přeprava</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"&nbsp;- Odtah vašeho vozidla jak pojízdného tak nepojízdného na Vámi určené místo <br>&nbsp;- přepravujeme osobní, terénní ale i malé dodávkové vozidla <br>&nbsp;- individuální domluva na přepravu menších strojů, techniky či materiálu<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Likvidace</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"- Zajistíme odtah a likvidaci vašeho vozidla zcela ZDARMA nemusíte se o nic starat, veškerou administrativu na úřadech vyřídíme za Vás <br>&nbsp;- vystavení dokladu o převzetí vozidla samozřejmostí\n<br>- vystavení dokladu o likvidaci vozidla samozřejmostí <br>&nbsp;- vyřazení vozidla z registru&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"r71knca6aoq"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Ceny odtahů a služeb<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"- Snažíme se vyhovět co nejvíce zákazníkům tudíž ceny určujeme individuálně <br>- Jednání na odborech dopravy (přepis vozu, zánik ale i vyřazení z registru) dle složitosti<br>- cena za odtah/přepravu je od <span style=\"color: var(--color-custom-2--15); font-weight: bold;\">25 Kč/km</span> (počítá se příjezd, odvoz a následný odjezd na místo) + manipulace s vozidlem od <span style=\"color: var(--color-custom-2--15); font-weight: bold;\">4OO Kč</span><br>- ceny výkupu vozidel určíme buď dle fotografií které nám můžete zaslat na <br>e-mailovou adresu nebo telefonicky podle popisu stavu, závad a roku výroby vozidla.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1vzas7h pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/339f71499a8443b09660234668b9da7c_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/339f71499a8443b09660234668b9da7c_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/339f71499a8443b09660234668b9da7c_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/339f71499a8443b09660234668b9da7c_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/339f71499a8443b09660234668b9da7c_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/339f71499a8443b09660234668b9da7c_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/339f71499a8443b09660234668b9da7c_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/37892/339f71499a8443b09660234668b9da7c_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box" style={{"maxWidth":526}} content={"<span style=\"color: var(--white);\">Působíme po celé ČR a jsme stále na telefonu<br><br>&nbsp;Nebojte se nás kontaktovat &nbsp;</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 ff--2 fs--20 ls--002" use={"page"} href={"/#paticka"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ytw53tsa89f"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Místo pro vaše dotazy"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26" content={"Natahnemto.cz"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"Brno - venkov<br>Česká Republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--26" content={"Kontakt"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"+420 775 300 095<br>natahnemto@seznam.cz"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" style={{"maxWidth":304}} content={"<br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"maxWidth":304}} content={"www.natahnemto.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}